#nav-tab {
  position: absolute;
  left: 275px;
  appearance: none;
  margin: 0;
  margin-top: -15px;
  padding: 10px 14px;
  background: var(--outlined);
  border: 1px solid var(--primary-light);
  border-left: none;
  border-radius: 0px 10px 10px 0px;
  color: var(--primary);
  box-shadow: inset 2px 0px 2px rgba(0,0,0,0.05);

  & sl-icon {
    vertical-align: middle;
  }

  display: none;

  @media (max-width: 1023px) {
    display: block;
  }
}

back-to-top {
  display: block;
  pointer-events: none;
  opacity: 0;
  position: fixed;
  z-index: 99;
  bottom: 20px;
  right: 1px;
  width: 45px;
  height: 45px;

  translate: 10px 0px;
  transition: all 0.3s;

  &[active] {
    translate: 0px 0px;
    opacity: 1;
    pointer-events: auto;
  }

  & button {
    appearance: none;
    padding: 10px 14px;
    background: var(--outlined);
    border: 1px solid var(--primary-light);
    border-right: none;
    border-radius: 10px 0px 0px 10px;
    color: var(--primary);
    box-shadow: -1px 0px 4px rgba(0,0,0,0.08), inset -5px 0px 2px rgba(0,0,0,0.05);

    & sl-icon {
      vertical-align: middle;
    }
  }
}

#tagline {
  margin-bottom: 5rem;
}

blockquote, a-card, .featured-image > img {
  border: 0.5px solid var(--outlined);
  border-radius: 5px;
  box-shadow: 0px 10px 20px rgba(0,0,0,0.1);
}

blockquote.link-excerpt {
  padding-top: 1.75em;
  padding-bottom: 1.33em;

  &, & p {
    position: relative;
  }

  & ui-label {
    font-family: var(--heading-font);
    text-decoration: none;
    line-height: 1em;
    color: var(--color-quoted);
    font-size: 8rem;
    position: absolute;
    top: 0;
    left: 6px;
  }
}

a-card blockquote {
  border: 1px dotted var(--primary-light) !important;
  border-radius: 0 !important;
  font-size: 108% !important;
}

a-card figure {
  float: right;
  margin-top: -1rem;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 1.5rem;

  & > img {
    width: 260px;
    height: 275px;
    object-fit: cover;
    object-position: 50% 20%;
    border-radius: 50%;
    border: 7px solid var(--portrait-border-color);
  }

  @media (max-width: 700px) {
    float: none;
    margin-top: 0;
    text-align: center;
  }
}

resources-feed {
  &[skip-last-hr] {
    & hr:last-of-type {
      display: none;
    }
  }
}

article-content {
  display: block;

  & p, & li {
    & strong, & b {
      font-size: 105%;
    }
  }
}

article-content > p:first-of-type:first-letter {
  font-size: 3.5em;
  font-weight: bold;
  float: left;
  line-height: 1em;
  margin: -9px .075em -5px 0;
  font-style: normal;
  color: var(--heading-text-color);
}

article.mono-styled {
  & > :is(p, ul, ol) {
    font-family: mononoki;
    font-variant-ligatures: 'ss01';
  }

  &:has(> h2) {
    & category-indicator p {
      margin-bottom: 2rem;
    }
  }
}

body.post:not(.category-pictures) {
  & .featured-image {
    margin-bottom: 3rem;
  }
}

@media (min-width: 1280px) {
  .featured-image {
    margin-right: -1rem;
  }

  body.post article-content {
    padding-left: 1rem;
  }
}

.hashtag {
  font-weight: bold;
}

responsive-embed {
  display: block;
  position: relative;
  height: 0;
  margin-bottom: 1.75rem;
  padding-bottom: 56.25%;
  overflow: hidden;
  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

published-date, category-indicator {
  & a {
    text-decoration: none;
  }
  & p {
    background: var(--secondary);
    color: white;
    padding: 5px 10px;
    width: fit-content;
    white-space: nowrap;
    font-size: 80%;
    margin: 3rem auto 3.5rem;
    border-radius: 2px;
  }
}

category-indicator {
  & p {
    margin: 3rem 0 2.5rem;
    font-size: 90%;
  }

  &:first-child {
    & p {
      margin: 1rem 0 2rem;
    }
  }
}

img.podcast-cover {
  display: block;
  box-shadow: 0px 12px 30px rgba(0,0,0,0.3);
  border-radius: 3px;
}

#tag-cloud {
  line-height: 1.9;
  @media (min-width: 400px) {
    text-align: justify;
  }

  & a {
    margin-left: 0.2em;
    margin-right: 0.2em;
    display: inline-block;
  }
  & a.set-2 {
    font-size: 1.2em;
  }
  & a.set-3 {
    font-size: 1.5em;
  }
  & a.set-4 {
    font-size: 1.6em;
  }
  & a.set-5 {
    font-size: 1.7em;
  }
}

.email-button {
  font-weight: bold;
  font-size: inherit !important;
}

recent-photos {
  grid-template-columns: 1fr 1fr;
  display: grid;
  gap: 15px;
  align-items: center;
}

recent-photos img {
  object-fit: cover;
  width: 100%;
  height: 6.5rem;
}

recent-photos a {
  transform: translateY(0px);
  transition: transform 0.33s;
}
recent-photos a:hover {
  transform: translateY(-7px);
}

toottown-comments-loader p {
  font-size: 90%;
}

toottown-comments:not(:empty) {
  display: block;
  margin-inline: min(3lvi, 1.5em);
}

toottown-comment {
  --link-color: var(--primary);
  --header-link-color: var(--strong-color);
  padding-block-end: 1.5em;
  border-block-end: solid 3px var(--grey-outlined);
  font-size: var(--content-scaling);
}

toottown-comment:last-child {
  padding-block-end: 0;
  border-block-end: none;
}
