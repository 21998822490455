@font-face {
  font-family: "Metropolis";
  src: url("../fonts/metropolis-medium_500-webfont.woff2") format("woff2"),
    url("../fonts/metropolis-medium_500-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Metropolis";
  src: url("../fonts/metropolis_medium_italic_500-webfont.woff2") format("woff2"),
    url("../fonts/metropolis_medium_italic_500-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Metropolis";
  src: url("../fonts/metropolis_bold_700-webfont.woff2") format("woff2"),
    url("../fonts/metropolis_bold_700-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Tiempos Fine";
  src: url("../fonts/tiempos-fine-web-bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'mononoki';
  src: url('../fonts/mononoki-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'mononoki';
  src: url('../fonts/mononoki-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'mononoki';
  src: url('../fonts/mononoki-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

:root {
  --text-color: #292726;
  --body-background: #f9f9f9;
  --body-font: Metropolis, sans-serif;
  --body-size: 18px;
  --line-height: 1.4;
  --content-scaling: 100%;

  --primary: #007c52;
  --primary: oklch(51.9% 0.155 160.72);
  --primary-button: var(--primary);
  --primary-light: #b1c8bf;
  --secondary: #fd5300;
  --secondary: oklch(67.1% 0.228 38.475);
  --outlined: white;
  --grey-outlined: #eee;
  --color-quoted: #e2dfdd;
  --field-outlined: #ccc;
  --field-background: white;
  --strong-color: var(--text-color);

  --heading-font: "Tiempos Fine", serif;
  --heading-text-color: black;
  --heading-2-size: 3.5rem;
  --heading-3-size: 1.8rem;

  @media (max-width: 1023px) {
    --heading-2-size: 2.2rem;
    --heading-3-size: 1.35rem;
  }
  @media (max-width: 500px) {
    --content-scaling: 90%;
  }

  --footer-text-color: var(--portrait-border-color);

  --card-border-color: var(--heading-text-color);
  --portrait-border-color: #8f8c80;
}

body.dark-mode {
  --text-color: #c9c9c9;
  --body-background: #211f1e;

  --primary: #00de9f;
  --primary: oklch(80% 0.205 164.09);
  --primary-light: #5e736a;
  --outlined: #191816;
  --grey-outlined: #404040;
  --color-quoted: #44413b;
  --field-outlined: #777;
  --field-background: var(--body-background);
  --strong-color: #fff;

  --heading-text-color: #fff;

  --footer-text-color: var(--field-outlined);

  --card-border-color: #344941;
  --portrait-border-color: var(--secondary);
}
